/* Formatierungen für responsives Cards */

section.team  cards.dienstleistungen { 
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	column-gap: var(--sl-spacing-2x-large);
	row-gap:  var(--sl-spacing-2x-large);
 & sl-card {
		display: flex;
			text-align: center;
			align-content: space-between;
			flex-direction: row; 
			min-width: 30ch;

		

		& sl-avatar { 
			align-self: center;
			--size: 5em;
		}
	}

		& card-inner { flex: 1 }
	}



