/* Import the base Shoelace stylesheet: */
@import "@shoelace-style/shoelace/dist/themes/light.css";
 @import "reset.css"; 
 @import "global.css";  
@import "bar.css"; 
@import "breakpoints.css";
@import "footer.css";
/*@import "shoelace.css"  */
@import "hero.css";
/*@import "card.css"; */
@import "the-card.css";
@import "menschen.css";
:root {

	--sl-color-topo-50: rgb(248 250 251);
	--sl-color-transparent-50: rgba(248,250,251,0.8);
	--sl-color-transparent: rgba(255,255,255,0.8);
	--sl-color-topo-100: rgb(232 237 242);
	--sl-color-topo-200: rgb(214 223 233);
	--sl-color-topo-300: rgb(195 208 222);
	--sl-color-topo-400: rgb(169 188 208);
	--sl-color-topo-500: rgb(136 163 190);
	--sl-color-topo-600: rgb(100 135 170);
	--sl-color-topo-700: rgb(70 111 154);
	--sl-color-topo-800: rgb(44 92 140);
	--sl-color-topo-900: rgb(30 65 101);
	--sl-color-topo-950: rgb(18 40 62);

  --background-color: var(--sl-color-topo-50);
  --body-color: var(--sl-color-topo-800);
  --heading-color: var(--sl-color-neutral-500);
  --action-color: var(--sl-color-red-800);
	--max-body-width: 1680px;
  --max-content-width: 80rem;   /*  kann auch 65rem sein */
  --display-serif: Lora, serif;  
	--color-footer: var(--sl-color-topo-50);
	/* das akzentuierte Y */
	--color-hieronymus: #20A75A; 
	/*  Textkörper */
	--hieronymus-color: var(--sl-color-sky-700);

}
*, *:before, *:after { box-sizing: inherit; }
body {
  background: var(--sl-color-topo-50);
  color: var(--sl-color-topo-900);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
	min-height: 100vh;
/*  margin: 0 var(--sl-spacing-x-small);  */
  font-size:var(--sl-font-size-large);
  line-height: var(--sl-line-height-dense);
}

