/* Die Vorlage für die Formatierung stammt von der Bridgetown Homepage        */
/* Es wird ein minimal resposives flexbox basiertes Tabellenlayout realisiert */
footer {

	background: 
/*		 	 radial-gradient(var(--sl-color-topo-700) 20% , var(--sl-color-topo-900) 140%, var(--sl-color-topo-100) 25%) ;*/
		 	 radial-gradient(var(--sl-color-sky-900) 20% , var(--sl-color-orange-900) 140%, var(--sl-color-topo-50) 25%) ;
  /* background: var( --sl-color-red-950 );  /* var(--color-dark-gray); */  
  color: var(--color-footer);
  /* padding: 3rem 20px; */
  margin-top: var(--sl-spacing-2x-large);
  & h4 {
    color: white;
    margin-top: var(--sl-spacing-small);
  }
  & teaser {
		& p {
			text-align: justify;
			margin-bottom: var(--sl-spacing-medium);
			padding: var(--sl-spacing-small);
		}
			& logo { 
				font-size: var(--sl-font-size-large);
				font-family: var(--display-serif);
				text-shadow: 2px 2px 2px var(--sl-color-gray-700);
				letter-spacing: var(--sl-spacing-2x-small);
				font-weight: var(--sl-font-weight-bold);
			  margin-right: -2px; 	
				/* color: var( --sl-color-topo-900 ); */


				/*font-size: clamp(2rem, 4vw, 3rem);*/

				& letter {
					color: var(--color-hieronymus);
					text-shadow: 1px 1px 1px var(--color-light-gray); 
					vertical-align: -0.3467rem;
				}
			}
		}
  & layout-columns {
    display: flex;
    text-align: left;
    justify-content: space-around;
		align-items: center; 
    gap: var( --sl-spacing-x-large );
    margin-bottom: var(--sl-spacing-2x-large);
    font-size: 90%;

    @media (min-width: 1200px) {
      font-size: calc(0.65rem + 0.4vw);
    }

    @media (--nav-minimal) {
      flex-direction: column;
      gap: 0;
    }
  }

  & layout-column {
		padding: var(--sl-spacing-small) 0;

    @media (--nav-fullsize) {
      padding-top: calc(0.875rem - 0.5vw);

      &:first-child { padding-top: 0; }

		}
  }

  & sl-button[size="small"] {
    --sl-button-font-size-small: 0.8em;
    --sl-input-height-small: calc(1.5em + 15px);
  }
}
