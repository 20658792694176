:root {
  --sl-bar-padding: var(--sl-spacing-small);
}

/*   Source of nav-effects: https://codepen.io/maheshambure21/pen/QwXa */
nav sl-bar {
  & a {
    display: inline-block;
    margin-right: var(--sl-spacing-small);
		position: relative;

		transition: all 2s;
		&:after {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 0%;
			content: '.';
			color: transparent;
			background: #aaa;
			height: 1px;
		}

		&:hover { 
			color: #555;
		  &:after { 
				width: 100%;  
				z-index: -10;
				animation: fill 1s forwards;
				opacity: 1;
			}
		} 

    @media (--sl-breakpoint-lg) {
      margin-right: 0.5rem;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (--nav-minimal) {
    flex-direction: column;

    &[expanded="false"] {
      & sl-bar-item[expandable] {
        display: none;
      }
    }
    &[expanded="true"] {
      margin-bottom: var(--sl-spacing-x-large);
    }
  }

  & sl-bar-item[mobileonly] {
    height: 3.25rem;
  }

  @media (--nav-fullsize) {
    & sl-bar-item[mobileonly] {
      display: none;
    }
  }

  & sl-bar-item[branding] a {
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  /*  padding: 8px; */
		border: none;
/*    border-radius: 12px;
    background: #0c202d00); */
  }
}

sl-bar {
  display: flex;
}

sl-bar[wrap] {
  flex-wrap: wrap;
}
sl-bar[spaced="evenly"] {
  justify-content: space-evenly;
}
sl-bar[spaced="between"] {
  justify-content: space-between;
}
sl-bar[align-items="start"] {
  align-items: flex-start;
}
sl-bar[align-items="center"] {
  align-items: center;
}
sl-bar[align-items="end"] {
  align-items: flex-end;
}
sl-bar[wrap] > * {
  flex-basis: 100%;
}
sl-bar[autosize] > * {
  flex-basis: unset;
}
sl-bar[reverse] {
  flex-direction: row-reverse;
}

sl-bar-item {
  padding: var(--sl-bar-padding, 0);
}

sl-bar-item[size~="1/10"] {
  flex-basis: 10% !important;
}
sl-bar-item[size~="2/10"] {
  flex-basis: 20% !important;
}
sl-bar-item[size~="3/10"] {
  flex-basis: 30% !important;
}
sl-bar-item[size~="4/10"] {
  flex-basis: 40% !important;
}
sl-bar-item[size~="5/10"] {
  flex-basis: 50% !important;
}
sl-bar-item[size~="6/10"] {
  flex-basis: 60% !important;
}
sl-bar-item[size~="7/10"] {
  flex-basis: 70% !important;
}
sl-bar-item[size~="8/10"] {
  flex-basis: 80% !important;
}
sl-bar-item[size~="9/10"] {
  flex-basis: 90% !important;
}
sl-bar-item[size~="1/3"] {
  flex-basis: 33.33% !important;
}
sl-bar-item[size~="2/3"] {
  flex-basis: 66.66% !important;
}
sl-bar-item[size~="1/4"] {
  flex-basis: 25% !important;
}
sl-bar-item[size~="2/4"],
sl-bar-item[size~="1/2"] {
  flex-basis: 50% !important;
}
sl-bar-item[size~="3/4"] {
  flex-basis: 75% !important;
}
sl-bar-item[size~="full"] {
  flex-basis: 100% !important;
}

@media (--sl-breakpoint-xs) {
  sl-bar-item[size~="xs:1/10"] {
    flex-basis: 10% !important;
  }
  sl-bar-item[size~="xs:2/10"] {
    flex-basis: 20% !important;
  }
  sl-bar-item[size~="xs:3/10"] {
    flex-basis: 30% !important;
  }
  sl-bar-item[size~="xs:4/10"] {
    flex-basis: 40% !important;
  }
  sl-bar-item[size~="xs:5/10"] {
    flex-basis: 50% !important;
  }
  sl-bar-item[size~="xs:6/10"] {
    flex-basis: 60% !important;
  }
  sl-bar-item[size~="xs:7/10"] {
    flex-basis: 70% !important;
  }
  sl-bar-item[size~="xs:8/10"] {
    flex-basis: 80% !important;
  }
  sl-bar-item[size~="xs:9/10"] {
    flex-basis: 90% !important;
  }
  sl-bar-item[size~="xs:1/3"] {
    flex-basis: 33.33% !important;
  }
  sl-bar-item[size~="xs:2/3"] {
    flex-basis: 66.66% !important;
  }
  sl-bar-item[size~="xs:1/4"] {
    flex-basis: 25% !important;
  }
  sl-bar-item[size~="xs:2/4"],
  sl-bar-item[size~="xs:1/2"] {
    flex-basis: 50% !important;
  }
  sl-bar-item[size~="xs:3/4"] {
    flex-basis: 75% !important;
  }
  sl-bar-item[size~="xs:full"] {
    flex-basis: 100% !important;
  }
}

@media (--sl-breakpoint-sm) {
  sl-bar-item[size~="sm:1/10"] {
    flex-basis: 10% !important;
  }
  sl-bar-item[size~="sm:2/10"] {
    flex-basis: 20% !important;
  }
  sl-bar-item[size~="sm:3/10"] {
    flex-basis: 30% !important;
  }
  sl-bar-item[size~="sm:4/10"] {
    flex-basis: 40% !important;
  }
  sl-bar-item[size~="sm:5/10"] {
    flex-basis: 50% !important;
  }
  sl-bar-item[size~="sm:6/10"] {
    flex-basis: 60% !important;
  }
  sl-bar-item[size~="sm:7/10"] {
    flex-basis: 70% !important;
  }
  sl-bar-item[size~="sm:8/10"] {
    flex-basis: 80% !important;
  }
  sl-bar-item[size~="sm:9/10"] {
    flex-basis: 90% !important;
  }
  sl-bar-item[size~="sm:1/3"] {
    flex-basis: 33.33% !important;
  }
  sl-bar-item[size~="sm:2/3"] {
    flex-basis: 66.66% !important;
  }
  sl-bar-item[size~="sm:1/4"] {
    flex-basis: 25% !important;
  }
  sl-bar-item[size~="sm:2/4"],
  sl-bar-item[size~="sm:1/2"] {
    flex-basis: 50% !important;
  }
  sl-bar-item[size~="sm:3/4"] {
    flex-basis: 75% !important;
  }
  sl-bar-item[size~="sm:full"] {
    flex-basis: 100% !important;
  }
}

@media (--sl-breakpoint-md) {
  sl-bar-item[size~="md:1/10"] {
    flex-basis: 10% !important;
  }
  sl-bar-item[size~="md:2/10"] {
    flex-basis: 20% !important;
  }
  sl-bar-item[size~="md:3/10"] {
    flex-basis: 30% !important;
  }
  sl-bar-item[size~="md:4/10"] {
    flex-basis: 40% !important;
  }
  sl-bar-item[size~="md:5/10"] {
    flex-basis: 50% !important;
  }
  sl-bar-item[size~="md:6/10"] {
    flex-basis: 60% !important;
  }
  sl-bar-item[size~="md:7/10"] {
    flex-basis: 70% !important;
  }
  sl-bar-item[size~="md:8/10"] {
    flex-basis: 80% !important;
  }
  sl-bar-item[size~="md:9/10"] {
    flex-basis: 90% !important;
  }
  sl-bar-item[size~="md:1/3"] {
    flex-basis: 33.33% !important;
  }
  sl-bar-item[size~="md:2/3"] {
    flex-basis: 66.66% !important;
  }
  sl-bar-item[size~="md:1/4"] {
    flex-basis: 25% !important;
  }
  sl-bar-item[size~="md:2/4"],
  sl-bar-item[size~="md:1/2"] {
    flex-basis: 50% !important;
  }
  sl-bar-item[size~="md:3/4"] {
    flex-basis: 75% !important;
  }
  sl-bar-item[size~="md:full"] {
    flex-basis: 100% !important;
  }
}

@media (--sl-breakpoint-lg) {
  sl-bar-item[size~="lg:1/10"] {
    flex-basis: 10% !important;
  }
  sl-bar-item[size~="lg:2/10"] {
    flex-basis: 20% !important;
  }
  sl-bar-item[size~="lg:3/10"] {
    flex-basis: 30% !important;
  }
  sl-bar-item[size~="lg:4/10"] {
    flex-basis: 40% !important;
  }
  sl-bar-item[size~="lg:5/10"] {
    flex-basis: 50% !important;
  }
  sl-bar-item[size~="lg:6/10"] {
    flex-basis: 60% !important;
  }
  sl-bar-item[size~="lg:7/10"] {
    flex-basis: 70% !important;
  }
  sl-bar-item[size~="lg:8/10"] {
    flex-basis: 80% !important;
  }
  sl-bar-item[size~="lg:9/10"] {
    flex-basis: 90% !important;
  }
  sl-bar-item[size~="lg:1/3"] {
    flex-basis: 33.33% !important;
  }
  sl-bar-item[size~="lg:2/3"] {
    flex-basis: 66.66% !important;
  }
  sl-bar-item[size~="lg:1/4"] {
    flex-basis: 25% !important;
  }
  sl-bar-item[size~="lg:2/4"],
  sl-bar-item[size="lg:1/2"] {
    flex-basis: 50% !important;
  }
  sl-bar-item[size~="lg:3/4"] {
    flex-basis: 75% !important;
  }
  sl-bar-item[size~="lg:full"] {
    flex-basis: 100% !important;
  }
}

@media (--sl-breakpoint-xl) {
  sl-bar-item[size~="xl:1/10"] {
    flex-basis: 10% !important;
  }
  sl-bar-item[size~="xl:2/10"] {
    flex-basis: 20% !important;
  }
  sl-bar-item[size~="xl:3/10"] {
    flex-basis: 30% !important;
  }
  sl-bar-item[size~="xl:4/10"] {
    flex-basis: 40% !important;
  }
  sl-bar-item[size~="xl:5/10"] {
    flex-basis: 50% !important;
  }
  sl-bar-item[size~="xl:6/10"] {
    flex-basis: 60% !important;
  }
  sl-bar-item[size~="xl:7/10"] {
    flex-basis: 70% !important;
  }
  sl-bar-item[size~="xl:8/10"] {
    flex-basis: 80% !important;
  }
  sl-bar-item[size~="xl:9/10"] {
    flex-basis: 90% !important;
  }
  sl-bar-item[size~="xl:1/3"] {
    flex-basis: 33.33% !important;
  }
  sl-bar-item[size~="xl:2/3"] {
    flex-basis: 66.66% !important;
  }
  sl-bar-item[size~="xl:1/4"] {
    flex-basis: 25% !important;
  }
  sl-bar-item[size~="xl:2/4"],
  sl-bar-item[size~="xl:1/2"] {
    flex-basis: 50% !important;
  }
  sl-bar-item[size~="xl:3/4"] {
    flex-basis: 75% !important;
  }
  sl-bar-item[size~="xl:full"] {
    flex-basis: 100% !important;
  }
}

@media (--sl-breakpoint-xxl) {
  sl-bar-item[size~="xxl:1/10"] {
    flex-basis: 10% !important;
  }
  sl-bar-item[size~="xxl:2/10"] {
    flex-basis: 20% !important;
  }
  sl-bar-item[size~="xxl:3/10"] {
    flex-basis: 30% !important;
  }
  sl-bar-item[size~="xxl:4/10"] {
    flex-basis: 40% !important;
  }
  sl-bar-item[size~="xxl:5/10"] {
    flex-basis: 50% !important;
  }
  sl-bar-item[size~="xxl:6/10"] {
    flex-basis: 60% !important;
  }
  sl-bar-item[size~="xxl:7/10"] {
    flex-basis: 70% !important;
  }
  sl-bar-item[size~="xxl:8/10"] {
    flex-basis: 80% !important;
  }
  sl-bar-item[size~="xxl:9/10"] {
    flex-basis: 90% !important;
  }
  sl-bar-item[size~="xxl:1/3"] {
    flex-basis: 33.33% !important;
  }
  sl-bar-item[size~="xxl:2/3"] {
    flex-basis: 66.66% !important;
  }
  sl-bar-item[size~="xxl:1/4"] {
    flex-basis: 25% !important;
  }
  sl-bar-item[size~="xxl:2/4"],
  sl-bar-item[size~="xxl:1/2"] {
    flex-basis: 50% !important;
  }
  sl-bar-item[size~="xxl:3/4"] {
    flex-basis: 75% !important;
  }
  sl-bar-item[size~="xxl:full"] {
    flex-basis: 100% !important;
  }
}
